'use client';

import React, { useContext } from 'react';
import { isFragment } from 'react-is';
import { getDefaultEventAction } from './eventAction';
import { type TrackEvent } from './Tracker';
import { TrackingContext } from './TrackingProvider';
import { type EventAction, type TrackingData } from './types';
import { useTracker } from './useTracker';

export interface TrackProps {
  children?: React.ReactElement;

  /**
   * Optional action to send with analytics data.
   * @default 'click'
   */
  eventAction?: EventAction;

  /**
   * Label to send with analytics data.
   * Analytics events will not be sent unless a label is provided.
   */
  eventLabel: string;

  /**
   * Custom tracking data to pass through to Tracker.
   */
  customData?: TrackingData;

  /**
   * A dom/react event to watch and attach tracking data to
   * @default 'onClick'
   */
  domEvent?: keyof Omit<
    React.DOMAttributes<any>,
    'children' | 'dangerouslySetInnerHTML'
  >;

  /**
   * Disable tracking, returns child as is.
   */
  disabled?: boolean;

  /**
   * Custom tracking event to pass through to Tracker.
   */
  event?: TrackEvent;
}

export const Track: React.FC<TrackProps> = ({
  children,
  eventAction,
  eventLabel,
  customData,
  domEvent = 'onClick',
  disabled,
  event,
}) => {
  const child = React.Children.only(children);

  const { isReactTrackingEnabledForTree } = useContext(TrackingContext);

  const tracker = useTracker({
    eventAction,
    ...customData,
  });

  if (disabled || !eventLabel || !React.isValidElement(child)) {
    return <>{child}</>;
  }

  const originalEventHandler = (child.props as Record<string, unknown>)[
    domEvent
  ];

  if (isFragment(child)) {
    throw new Error('Track does not support Fragment.');
  }

  return React.cloneElement(
    child,
    isReactTrackingEnabledForTree
      ? {
          [domEvent]: (e: React.SyntheticEvent<HTMLElement>) => {
            if (typeof originalEventHandler === 'function') {
              originalEventHandler(e);
            }
            if (!e.defaultPrevented) {
              tracker.sendEvent(event, {
                eventLabel,
                eventAction:
                  eventAction || getDefaultEventAction(e.type, e.currentTarget),
              });
            }
          },
        }
      : {
          [`data-track-${domEvent.toLowerCase()}`]: JSON.stringify({
            eventAction,
            eventLabel,
            event,
            ...customData,
          }),
        },
  );
};
