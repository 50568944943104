export enum ErrorMessages {
  TEST_ERROR = 'TEST_ERROR',
  /* COMPONENTS */
  FORMAT_DISTANCE_INVALID_LOCALE = 'FORMAT_DISTANCE_INVALID_LOCALE',
  MAXI_CARD_INVALID_RETAILER = 'MAXI_CARD_INVALID_RETAILER',
  RETAILER_CARD_MAXI_DIALOG_INVALID_CONTENT = 'RETAILER_CARD_MAXI_DIALOG_INVALID_CONTENT',
  /* UTILS */
  INVALID_CAPABILITY = 'INVALID_CAPABILITY',
  /* RETAILER FETCHING */
  AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS = 'AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS',
  NO_INITIAL_RETAILERS = 'NO_INITIAL_RETAILERS',
  QUERY_PATH_ERROR = 'QUERY_PATH_ERROR',
  DIPLOMAT_RETAILERS_FETCHING_ERROR = 'DIPLOMAT_RETAILERS_FETCHING_ERROR',
  RETAILERS_FETCHING_ERROR = 'RETAILERS_FETCHING_ERROR',
  RETAILER_RESPONSE_DATA_NOT_FOUND = 'RETAILER_RESPONSE_DATA_NOT_FOUND',
  DIPLOMAT_RETAILERS_DATA_NOT_FOUND = 'DIPLOMAT_RETAILERS_DATA_NOT_FOUND',
  /* PROVIDERS */
  USE_RETAILERS_MUST_BE_USED_IN_RETAILERS_PROVIDER = 'USE_RETAILERS_MUST_BE_USED_IN_RETAILERS_PROVIDER',
  /* MAP */
  MAP_UI_ERROR = 'MAP_UI_ERROR',
}
