import { useState } from 'react';
import { useTracker } from '@volvo-cars/tracking';
import { TRACKING_CATEGORY } from '../../tracking';
import { useStore } from '../providers/StoreProvider';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useSearchInput } from '../providers/SearchInputProvider';

const navigatorSettings = {
  timeout: Infinity,
  enableHighAccuracy: true,
  maximumAge: 0,
};

type UseUserLocationProps = {
  setResultsFound?: (setResultsFound: boolean) => void;
};

export const useUserLocation = ({
  setResultsFound,
}: UseUserLocationProps = {}) => {
  const { dispatch } = useStore();
  const translate = useSharedComponentsTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const { inputRef, setInput } = useSearchInput();
  const track = useTracker();

  const navigatorCallback = ({ coords }: GeolocationPosition) => {
    dispatch({
      type: 'SET_ADDRESS',
      payload: {
        place_id: '',
        description: '',
        matched_substrings: [],
        structured_formatting: {} as any,
        coords: {
          longitude: coords.longitude,
          latitude: coords.latitude,
        },
      },
    });
    inputRef?.current && inputRef?.current.blur();
    setResultsFound && setResultsFound(true);
    setIsLoading && setIsLoading(false);
  };
  const handleClick = () => {
    track.customEvent({
      eventAction: 'button|click',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: 'use my location',
    });
    if (!navigator || !navigator.geolocation) return;
    setIsLoading && setIsLoading(true);
    setInput({
      value: translate('RetailerSelector.search.myLocation') || 'My location',
      skipFetch: true,
      resetPredictions: true,
    });
    navigator.geolocation.getCurrentPosition(
      navigatorCallback,
      (err) => console.warn('Error getting user location', err),
      navigatorSettings,
    );
  };

  return {
    handleClick,
    isLoading,
  };
};
