import React from 'react';
import { Capability } from '../types/retailerCapabilities';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { translateCapability } from '../../utils/translateCapability';
import { PillGroup } from '../components/PillGroup';

type CapabilityPillsProps = { capabilities: Capability[] };

export const CapabilityPills = ({ capabilities }: CapabilityPillsProps) => {
  const translate = useSharedComponentsTranslate();
  const titles = capabilities.map((capability) =>
    translateCapability(capability, translate),
  );
  return <PillGroup pillTitles={titles} hasHorizontalScroll={false} />;
};
