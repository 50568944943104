import { useContext } from 'react';
import { SelectorContext } from 'src/providers/SelectorProvider';
import {
  useStore,
  useSearchInput,
  useRetailers,
} from '@vcc-package/retailer-selector';

export { useStore, useSearchInput, useRetailers };
export const useSelectorSettings = () => useContext(SelectorContext);
