import { CapabilityType } from '../../server-utils/gql/generated/types';

export const ALL_CAPABILITIES = [
  'sales',
  'service',
  'collision center',
  'diplomat',
  'test drive',
  'used car sales',
] as const;

export type Capability = (typeof ALL_CAPABILITIES)[number];

export const CapabilityGroups: Record<Capability, CapabilityType[]> = {
  sales: [CapabilityType.NewCarSales],
  'used car sales': [
    CapabilityType.UsedCarSales,
    CapabilityType.VolvoSelektMember,
  ],
  service: [CapabilityType.Workshop],
  'collision center': [CapabilityType.CertifiedDamageRepairWorkshop],
  diplomat: [CapabilityType.Diplomat],
  'test drive': [CapabilityType.TestDriveBooking],
};
