import { Filter } from './RetailerSearch.Filter';
import React, { useState } from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { SearchBox } from './SearchBox';
import { useRetailers } from '../providers/RetailersProvider';
import { useUserLocation } from '../hooks/useUserLocation';
import { NoResultsText } from './NoResultsText';
import { translateCapability } from '../../utils/translateCapability';
import { PillGroup } from '../components/PillGroup';

type SearchBlockProps = {
  showFilter?: boolean;
};
export const RetailerSearch = ({ showFilter = false }: SearchBlockProps) => {
  const { retailers, activeCapabilityFilters, availableCapabilityFilters } =
    useRetailers();
  const [showNoResultsText, setShowNoResultsText] = useState(true);

  const {
    handleClick: handleUseMyLocationClick,
    isLoading: isUserLocationLoading,
  } = useUserLocation();
  const translate = useSharedComponentsTranslate();

  const pillsToShow =
    !activeCapabilityFilters.length ||
    activeCapabilityFilters.length === availableCapabilityFilters.length
      ? [translate('RetailerSelector.capabilities.showingAll') || 'Showing all']
      : activeCapabilityFilters
          .sort(
            (a, b) =>
              availableCapabilityFilters.indexOf(a) -
              availableCapabilityFilters.indexOf(b),
          )
          .map((capability) => translateCapability(capability, translate));

  return (
    <div className="flex-col gap-24" data-testid="dealers:search">
      <h1 className="font-20 font-medium">
        {translate('RetailerSelector.search.header') || 'Find a Volvo retailer'}
      </h1>
      <SearchBox isUserLocationLoading={isUserLocationLoading}>
        {!!showFilter && (
          // Because the errorMessage increase the height of the TextInputWithIcon static pt is used
          <div className="until-md:hidden pt-4">
            <Filter />
          </div>
        )}
      </SearchBox>

      <div className="flex gap-8 items-center">
        <Icon icon="map-pin" size={24} />
        <button
          type="button"
          className="link-inline"
          onClick={handleUseMyLocationClick}
        >
          {translate('RetailerSelector.search.useMyLocationButtonLabel') ||
            'Use my location'}
        </button>
        {!!showFilter && (
          <div className="md:hidden ml-auto">
            <Filter />
          </div>
        )}
      </div>
      {!!showFilter && (
        <PillGroup pillTitles={pillsToShow} hasHorizontalScroll />
      )}
      {showNoResultsText && !isUserLocationLoading && !retailers?.length && (
        <NoResultsText
          handleLocationClick={handleUseMyLocationClick}
          handleClose={() => setShowNoResultsText(false)}
        />
      )}
    </div>
  );
};
