import React, { useEffect } from 'react';
import { SearchInputContextValue } from '../providers/SearchInputProvider';
import { parsePrediction } from '../../utils/parsePrediction';
import { AutocompleteResponse } from '../types/maps';
import styles from './SuggestionsBox.module.css';

type SuggestionsBoxProps = {
  handlePredictionSelect: any;
  predictions?: AutocompleteResponse['data'] | null;
  highlightIndex: number;
  setInput: SearchInputContextValue['setInput'];
};
export const SuggestionsBox = ({
  handlePredictionSelect,
  predictions,
  highlightIndex,
  setInput,
}: SuggestionsBoxProps): JSX.Element => {
  const predictionLength = predictions?.length;

  useEffect(() => {
    if (!predictionLength || !predictions) return;
    if (highlightIndex >= 0 && highlightIndex <= predictionLength - 1) {
      setInput({
        value: predictions[highlightIndex].description,
        skipFetch: true,
        resetPredictions: false,
      });
    }
  }, [highlightIndex, predictionLength, predictions, setInput]);
  return (
    <>
      {predictions?.length ? (
        //The positive and negative top margin is to avoid a gap from the parent to be added twice and make the searchblock grow in size when suggestionbox appears. (the parents adds the gap around the relative div even though it does not have a height)
        <div className="relative -mt-24">
          <ul className="absolute w-full p-16 bg-primary z-overlay mt-24 rounded-sm border-always-black border">
            {predictions?.map((prediction, i: number) => {
              return (
                <li key={i}>
                  <button
                    className={`${styles.button}tap-area w-full text-start overflow-hidden whitespace-nowrap rounded p-8`}
                    onClick={() => handlePredictionSelect(prediction)}
                    onMouseDown={(event: any) => event.preventDefault()}
                    data-testid="dealer:suggestions"
                    type="button"
                    // This is a fix for a safari issue where a crash happens when google translate plugin on phone is enabled
                    translate="no"
                  >
                    {parsePrediction(prediction).map((substr, key) => (
                      <p
                        key={key}
                        className={`${substr.type === 'bold' && 'font-medium'} whitespace-nowrap inline overflow-hidden`}
                      >
                        {substr.text}
                      </p>
                    ))}{' '}
                    <p className="text-secondary inline whitespace-nowrap inline">
                      {prediction.structured_formatting.secondary_text}
                    </p>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
};
