import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useKeyPress } from '@volvo-cars/react-utils';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useRetailers } from '../providers/RetailersProvider';
import { useMarketConfig } from '../providers/MarketConfigProvider';
import { StoreContextValue, useStore } from '../providers/StoreProvider';
import { useSearchInput } from '../providers/SearchInputProvider';
import { useAddressAutocomplete } from '../hooks/useAddressAutocomplete';
import { SuggestionsBox } from './SuggestionsBox';
import { TextInputWithIcon } from './TextInputWithIcon';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { logError } from '../logError';

type SearchBoxProps = React.PropsWithChildren<{
  isUserLocationLoading: boolean;
}>;

export const SearchBox = ({
  isUserLocationLoading,
  children,
}: SearchBoxProps) => {
  const { dispatch, address } = useStore();
  const { useAorRetailersAndZipCodeSearch } = useMarketConfig();
  const { siteSlug } = useCurrentMarketSite();
  const { isLoading: isRetailersLoading } = useRetailers();
  const { input, inputRef, setInput } = useSearchInput();
  const translate = useSharedComponentsTranslate();
  const [invalidZipCodeInput, setInvalidZipCodeInput] = useState(false);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInput({
        value,
        skipFetch: false,
        resetPredictions: false,
      });

      if (useAorRetailersAndZipCodeSearch) {
        // Temporary check to ensure that zip code search is only available for Netherlands market, because of mysterius bug crashing e2e tests
        if (siteSlug && !['nl', 'us'].includes(siteSlug)) {
          logError(
            `Zip code search should only be available for Netherlands market, but now it is true for ${siteSlug}`,
          );
          console.log(
            'Zip code search is only available for Netherlands market',
          );
        }
        // Regular expression to allow only numbers
        if (!/^\d*$/.test(value)) {
          setInvalidZipCodeInput(true);
        } else {
          setInvalidZipCodeInput(false);
        }
      }
    },
    [setInput, useAorRetailersAndZipCodeSearch, siteSlug],
  );
  const [submitting, setSubmitting] = useState(false);

  const { data: predictions, requestSearchInput: googleRequestSearchInput } =
    useAddressAutocomplete(
      input?.value,
      {
        debounceTime: 500,
        skip: input?.skipFetch,
        resetPredictions: input?.resetPredictions,
      },
      useAorRetailersAndZipCodeSearch,
    );

  const [highlightIndex, setHighlightIndex] = useState(-1);
  const predictionsLength = predictions?.length;

  useEffect(() => {
    if (!predictionsLength) return;
    if (highlightIndex > predictionsLength - 1) {
      setHighlightIndex(-1);
    }
    if (highlightIndex < -1) {
      setHighlightIndex(predictionsLength - 1);
    }
  }, [highlightIndex, predictionsLength]);

  const isUseMyLocation = address?.coords?.longitude;

  const onFocusAndBlur = (isOnFocus: boolean) => {
    const resetInput = isUseMyLocation && !predictionsLength;
    resetInput &&
      setInput({
        value: isOnFocus
          ? ''
          : translate('RetailerSelector.search.myLocation') || 'My location',
        skipFetch: !isOnFocus,
        resetPredictions: false,
      });
    setSuggestionsVisible(isOnFocus);
  };
  const blurInput = useCallback(() => {
    inputRef?.current && inputRef?.current.blur();
    setHighlightIndex(-1);
  }, [inputRef]);

  const handlePredictionSelect = useCallback(
    (prediction: StoreContextValue['address']) => {
      dispatch({ type: 'SET_ADDRESS', payload: prediction });
      setInput({
        value: prediction?.description || '',
        skipFetch: false,
        resetPredictions: false,
      });
      blurInput();
    },
    [blurInput, dispatch, setInput],
  );

  const handleInputSubmit = useCallback(() => {
    setSubmitting(false);
    if (!inputRef?.current?.value.trim().length) {
      inputRef?.current && inputRef?.current.focus();
      return;
    }
    if (!predictionsLength || !predictions || !googleRequestSearchInput) {
      return;
    }

    const predictionSelected =
      highlightIndex >= 0 && highlightIndex <= predictionsLength - 1;
    if (predictionSelected) {
      handlePredictionSelect(predictions[highlightIndex]);
    }

    const submitSearchInputFormatted = inputRef?.current?.value.replace(
      /[!.,;>'"()]/g,
      '',
    );
    const googleRequestSearchInputFormatted = googleRequestSearchInput.replace(
      /[!.,;>'"()]/g,
      '',
    );
    if (googleRequestSearchInputFormatted === submitSearchInputFormatted) {
      handlePredictionSelect(predictions[0]);
    } else {
      setSubmitting(true);
    }
  }, [
    inputRef,
    predictionsLength,
    predictions,
    googleRequestSearchInput,
    highlightIndex,
    handlePredictionSelect,
  ]);

  useKeyPress('Escape', blurInput);

  useEffect(() => {
    if (submitting) {
      handleInputSubmit();
    }
  }, [handleInputSubmit, submitting, googleRequestSearchInput]);

  return (
    <>
      <div className="flex gap-16">
        <div className="w-full">
          <TextInputWithIcon
            icon="search"
            name="search"
            label={
              useAorRetailersAndZipCodeSearch
                ? `${translate('RetailerSelector.search.zipInputPlaceholder') || 'Zip code'}`
                : `${translate('RetailerSelector.search.inputPlaceholder') || 'Search'}`
            }
            value={input.value}
            inputRef={inputRef}
            onChange={handleInputChange}
            handleSubmit={handleInputSubmit}
            autoComplete="off"
            extendedInputMode={
              useAorRetailersAndZipCodeSearch ? 'numeric' : 'search'
            }
            onFocus={() => onFocusAndBlur(true)}
            onBlur={() => onFocusAndBlur(false)}
            errorMessage={
              invalidZipCodeInput
                ? `${translate('RetailerSelector.search.invalidNumbersError') || 'Invalid input. Use numbers only'}`
                : undefined
            }
            isLoading={isRetailersLoading || isUserLocationLoading}
          />
        </div>

        {children}
      </div>
      {suggestionsVisible ? (
        <SuggestionsBox
          setInput={setInput}
          handlePredictionSelect={handlePredictionSelect}
          predictions={predictions}
          highlightIndex={highlightIndex}
        />
      ) : null}
    </>
  );
};
