export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Long: { input: any; output: any };
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLine4?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  countryName?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  languageCode: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AdministrativeAddress = {
  __typename?: 'AdministrativeAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  languageCode?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export enum Brand {
  /**
   * Fallback when value cannot be mapped to current schema
   * @deprecated Not to be used as input
   */
  Other = 'OTHER',
  Pol = 'POL',
  Vcc = 'VCC',
}

export type Capability = {
  __typename?: 'Capability';
  b2BEmail?: Maybe<Scalars['String']['output']>;
  b2BFax?: Maybe<Scalars['String']['output']>;
  b2BPhone?: Maybe<Scalars['String']['output']>;
  b2CEmail?: Maybe<Scalars['String']['output']>;
  b2CPhone?: Maybe<Scalars['String']['output']>;
  contactPerson?: Maybe<Scalars['String']['output']>;
  escalatedAlertEmail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<CapabilityType>;
  newAlertEmail?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<OpeningHours>;
  overdueAlertEmail?: Maybe<Scalars['String']['output']>;
  partsECommerceSales?: Maybe<Scalars['Boolean']['output']>;
  testDrivePadding?: Maybe<Scalars['Int']['output']>;
  testDriveSlotSize?: Maybe<Scalars['Int']['output']>;
};

export enum CapabilityType {
  /** A Retailer that offers damage repair services */
  BodyShop = 'body_shop',
  /** A Retailer that offers car rental services */
  CarRental = 'car_rental',
  /** A partner who performs car valet. */
  CarValet = 'car_valet',
  /** A Retailer that offers and is certified for damage repair services */
  CertifiedDamageRepairWorkshop = 'certified_damage_repair_workshop',
  /** A Retailer that offers charging outlets for electric vehicles  within the premises */
  ChargingStation = 'charging_station',
  /** This is used by markets to indicate the retailer offers CbV subscription/sales */
  DigitalCommerce = 'digital_commerce',
  /** A Retailer that handles handover of the Digital Commerce Products */
  DigitalCommerceProducts = 'digital_commerce_products',
  /** A Retailer having enabled the VCC solution for digital service booking for Volvo */
  DigitalWorkshopBooking = 'digital_workshop_booking',
  /** A Retailer that is certified to handle Diplomat sales and is capable to fulfiil official and international formalities */
  Diplomat = 'diplomat',
  /** A Retailer that executes repair and maintenance jobs with and without pre-booking */
  DropInService = 'drop_in_service',
  /** A Retailer that handles corporate, major account and fleet sales */
  FleetSales = 'fleet_sales',
  /** A Retailer that offers Insurance services */
  Insurance = 'insurance',
  /**
   * legal_invoice
   * @deprecated will be removed in future versions
   */
  LegalInvoice = 'legal_invoice',
  /** A Volvo partner who performs Lynk & Co. sales. */
  LnkNewCarSales = 'lnk_new_car_sales',
  /** A Volvo partner who offers Lynk & Co Pickup & Delivery. */
  LnkPickupAndDelivery = 'lnk_pickup_and_delivery',
  /** A Volvo partner who takes Lynk & Co. car returns. */
  LnkReturnPoint = 'lnk_return_point',
  /** A Volvo partner who offers Lynk & Co. Subscription. */
  LnkSubscription = 'lnk_subscription',
  /** A Volvo partner who performs Lynk & Co. used car sales. */
  LnkUsedCarSales = 'lnk_used_car_sales',
  /** A Volvo partner who performs Lynk & Co. warranty. */
  LnkWarranty = 'lnk_warranty',
  /** A Volvo partner who has a Lynk & Co. workshop. */
  LnkWorkshop = 'lnk_workshop',
  /** main */
  Main = 'main',
  /** A Retailer that is certified to handle sales to Military personnel and is capable to fulfiil official and international formalities */
  Military = 'military',
  /** A retailer who performs delivery of cars at the Volvo (VRE) facilities */
  NewCarHandover = 'new_car_handover',
  /** A Retailer that holds a Main Contract and is authorised for New Car Sales */
  NewCarSales = 'new_car_sales',
  /** Not Applicable */
  NotApplicable = 'not_applicable',
  /** Online sales */
  OnlineSales = 'online_sales',
  /**
   * Fallback when value cannot be mapped to current schema
   * @deprecated Not to be used as input
   */
  Other = 'other',
  /** A Retailer that holds a Main Contract or Authorised Repairer Contract and do parts sales over the counter */
  Parts = 'parts',
  /** A Volvo partner who performs PDI/PDS. */
  PdiAndPds = 'pdi_and_pds',
  /** A partner who are contracted to perform PDI/PDS services on Polestar Vehicles */
  PolPdiPdsService = 'pol_PDI_PDS_service',
  /** A Retailer having enabled the VCC solution for digital service booking for Polestar */
  PolDigitalWorkshopBooking = 'pol_digital_workshop_booking',
  /** A partner who operates a dedicated Polestar Handover Center */
  PolHandOverCenter = 'pol_hand_over_center',
  /** A partner who performs delivery of Polestar vehicles in existing Volvo (VRE) facilities */
  PolHandOverPoint = 'pol_hand_over_point',
  /** A partner who perform any version of Polestar's range of home delivery options (plated, covered etc.) */
  PolHomeDelivery = 'pol_home_delivery',
  /** A service partner who have signed the Addendum agreement to the Volvo dealer agreements related to Pick up & delivery services in relation to above */
  PolPickupAndDelivery = 'pol_pickup_and_delivery',
  /** A location where leasing or customers vehicles can be returned to */
  PolReturnPoint = 'pol_return_point',
  /** A service partner who have signed the Addendum agreement to the Volvo dealer agreements related to Service Contracts or included scheduled maintenance */
  PolServiceContract = 'pol_service_contract',
  /** A partner who operates a Polestar Space- i.e Polestar's unique retail environment */
  PolSpace = 'pol_space',
  /** A service partner who have signed the Addendum agreement to the Volvo dealer agreements related to base repairer services for Polestar vehicles such as parts ordering and warranty claims */
  PolWarrantyAndRepair = 'pol_warranty_and_repair',
  /** A partner who performs services related to tires and Winter wheels including wheel storage */
  PolWheelChangeAndStorage = 'pol_wheel_change_and_storage',
  /** Pre-Order */
  PreOrder = 'pre_order',
  /** A Retailer that operates a Shop for various goods like merchandise, etc within the premises */
  Shop = 'shop',
  /** A partner who performs software updates. */
  SoftwareUpdate = 'software_update',
  /** A partner who performs Special vehicle operations (e.g. Police cars, Ambulance, Military, etc.). */
  SpecialVehicleOperation = 'special_vehicle_operation',
  /** A Retailer who takes in online requests for test drive from the customers who would visit the partner location */
  TestDriveBooking = 'test_drive_booking',
  /** A Retailer who takes in online requests for test drive from the customers, wherein the retailer delivers a test drive car to the consumer at their location */
  TestDrivePickupAndDelivery = 'test_drive_pickup_and_delivery',
  /**
   * time_booking
   * @deprecated will be removed in future versions
   */
  TimeBooking = 'time_booking',
  /** Enable customers to view monthly lease pricing and payment estimator for in-stock vehicles in the car listing page in the US Market for select retailers */
  UsPilotPricingTransparency = 'us_pilot_pricing_transparency',
  /** A Retailer that executes the sales of used cars */
  UsedCarSales = 'used_car_sales',
  /** A Retailer that is certified for Volvo Personal Service */
  VolvoPersonalService = 'volvo_personal_service',
  /** A Retailer that fulfils the latest architectural VRE facility standards in the premises */
  VolvoRetailExperience = 'volvo_retail_experience',
  /** A Retailer that is certified and executes the sales of used cars within the Volvo Selekt Program */
  VolvoSelektMember = 'volvo_selekt_member',
  /** A retailer who performs wind screen repairs */
  WindscreenRepair = 'windscreen_repair',
  /** A Retailer that holds a Main Contract or Authorised Repairer Contract and is authorised for Repair and Maintenance works */
  Workshop = 'workshop',
}

export type Description = {
  __typename?: 'Description';
  aboutUs?: Maybe<Scalars['String']['output']>;
  languageCode: Scalars['String']['output'];
  promotionText?: Maybe<Scalars['String']['output']>;
  tagLine?: Maybe<Scalars['String']['output']>;
};

export type DistributionPoint = {
  __typename?: 'DistributionPoint';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  /** The code of the destination location. It typically follows the convention <2 character country code><3 character city code><4 digit numeric> */
  code: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  /** The description/name of the destination code. */
  description: Scalars['String']['output'];
  /** Indicates whether or not the location is set as default. */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isSalesCompanyStockLocation?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether or not the location is set as secondary default. */
  isSecondaryDefault?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
};

export enum FacilityType {
  /** Retailer facility which offers repair/maintenance/warranty works and parts sales in a workshop */
  AuthorizedRepairer = 'authorized_repairer',
  /** Retailer/third party facility that is handling damage repairs. */
  DamageRepairCenter = 'damage_repair_center',
  /** Retailer facility which offers new car sales in a showroom and repair / maintenance / warranty works and parts sales in a workshop */
  Main = 'main',
  /** Not Applicable */
  NotApplicable = 'not_applicable',
  /**
   * Fallback when value cannot be mapped to current schema
   * @deprecated Not to be used as input
   */
  Other = 'other',
  /** Facility that is used to offload some support work to free-up capacity at retailers. This facility is a non-consumer facing site. */
  PdiPdsCenter = 'pdi_pds_center',
  /** Facility that is used for communication on .com and sales at Volvo Studios. */
  PopupStore = 'popup_store',
  /** Retailer facility which is attached to a main facility of the Retailer, which is on a different location. */
  Satellite = 'satellite',
  /** Retailer facility which is attached to a main facility of the Retailer, which is on a different location and which offers repair / maintenance / warranty works and parts sales in a workshop. */
  SatelliteService = 'satellite_service',
  /** Facility that is used for communication on .com and sales at Volvo Studios. */
  Studio = 'studio',
}

export type GetPartnerOrganizationalUnitsQueryFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  afterSalesParmaPartnerCode?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Brand>;
  capabilities?: InputMaybe<Array<CapabilityType>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  deliveringPartner?: InputMaybe<Scalars['Boolean']['input']>;
  distributionPointCode?: InputMaybe<Scalars['String']['input']>;
  distributorId?: InputMaybe<Scalars['String']['input']>;
  excludeInactiveFacilities?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupKey?: InputMaybe<Scalars['String']['input']>;
  groupValue?: InputMaybe<Scalars['String']['input']>;
  hierarchyLevel1?: InputMaybe<Scalars['String']['input']>;
  hierarchyLevel2?: InputMaybe<Scalars['String']['input']>;
  hierarchyType?: InputMaybe<Scalars['String']['input']>;
  includeTerminated?: Scalars['Boolean']['input'];
  localRetailerCode?: InputMaybe<Scalars['String']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  orderingPartner?: InputMaybe<Scalars['Boolean']['input']>;
  parmaPartnerCode?: InputMaybe<Scalars['String']['input']>;
  partsECommerceSales?: InputMaybe<Scalars['Boolean']['input']>;
  roleCode?: InputMaybe<RoleCode>;
  /** Filters partner organizational units based on a territory type. Requires territoryZipCode and market filters to be provided in combination with this filter */
  territoryType?: InputMaybe<TerritoryType>;
  /** Filters partner organizational units responsible for a territory zip code. Requires market to be provided in combination with this filter */
  territoryZipCode?: InputMaybe<Scalars['String']['input']>;
  updatedAfter?: InputMaybe<Scalars['String']['input']>;
  visibleToPublic?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetPartnersByLocationQueryFilterInput = {
  brand?: InputMaybe<Brand>;
  capabilities?: InputMaybe<Array<CapabilityType>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  market?: InputMaybe<Scalars['String']['input']>;
};

export type Group = {
  __typename?: 'Group';
  /** The type of group. */
  key: Scalars['String']['output'];
  /** The name of the group the retailer belongs to for a given type of group. */
  value: Scalars['String']['output'];
};

export type Hierarchy = {
  __typename?: 'Hierarchy';
  /** The value of the parent node under a hierarchy type. A retailer can be linked to either a level 1 or level 2 node. */
  level1: Scalars['String']['output'];
  /** The value of the child node under a hierarchy type. Level 2 node is optional. */
  level2?: Maybe<Scalars['String']['output']>;
  /** The type of hierarchy, geographical or logical. */
  type: Scalars['String']['output'];
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  friday: Array<TimeInterval>;
  monday: Array<TimeInterval>;
  saturday: Array<TimeInterval>;
  specialDates: Array<SpecialDate>;
  sunday: Array<TimeInterval>;
  thursday: Array<TimeInterval>;
  tuesday: Array<TimeInterval>;
  wednesday: Array<TimeInterval>;
};

export type OrderingPartner = {
  __typename?: 'OrderingPartner';
  /** Flag to denote the default car ordering partner for a delivering partner. */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** The partner id of the  car ordering partner for a delivering partner. */
  partnerId: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PartnerLegalEntity = {
  __typename?: 'PartnerLegalEntity';
  authorizedCEO?: Maybe<Scalars['String']['output']>;
  authorizedRepresentative?: Maybe<Scalars['String']['output']>;
  commercialRegister?: Maybe<Scalars['String']['output']>;
  commercialRegisterNo?: Maybe<Scalars['String']['output']>;
  insurance?: Maybe<Scalars['String']['output']>;
  insuranceIndex?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  locationOfRegistration?: Maybe<Scalars['String']['output']>;
  salesTaxId?: Maybe<Scalars['String']['output']>;
  taxIds?: Maybe<Array<TaxId>>;
  tradeRegistration?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type PartnerOrganizationalUnit = {
  __typename?: 'PartnerOrganizationalUnit';
  /** Indicates if the retailer is active or not. */
  active: Scalars['Boolean']['output'];
  /** The administrative mailing addresses of a partner organizational unit */
  administrativeMailingAddresses?: Maybe<Array<AdministrativeAddress>>;
  /** Indicates what brand the distributor does the business with. */
  brand: Brand;
  /** The distributor id that gets invoiced. */
  buyerCode?: Maybe<Scalars['String']['output']>;
  /** Indicates whether or not the partner is on commercial delivery hold. */
  commercialDeliveryHoldIndicator?: Maybe<Scalars['Boolean']['output']>;
  /** A partner that delivers a car to a customer but is not able to place an order in nVista i.e., it is a non-car ordering partner. */
  deliveringPartner: Scalars['Boolean']['output'];
  /** The address locations where the car from the factory is delivered. These locations are managed and maintained by the OBL team. */
  distributionPoints?: Maybe<Array<DistributionPoint>>;
  /** The id of the distributor the retailer belongs to. */
  distributorId: Scalars['String']['output'];
  districtManagerEmail?: Maybe<Scalars['String']['output']>;
  /** The different groups a market has created for different business, reporting and analysis purposes. */
  groups?: Maybe<Array<Group>>;
  /** The different hierarchies a market has created for different business, reporting and analysis purposes. */
  hierarchies?: Maybe<Array<Hierarchy>>;
  localRetailerCode?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  managerDeputy1?: Maybe<Scalars['String']['output']>;
  managerDeputy2?: Maybe<Scalars['String']['output']>;
  market: Scalars['String']['output'];
  /** Indicates whether or not a distributer is on market hold. */
  marketHoldIndicator?: Maybe<Scalars['Boolean']['output']>;
  /** The distributor id that order the cars for the retailers. */
  ordererCode?: Maybe<Scalars['String']['output']>;
  /** Indicates if the retailer can create a car order in VISTA. */
  orderingPartner: Scalars['Boolean']['output'];
  /** The list of car ordering partners that place an order on behalf of a delivering partner. */
  orderingPartners?: Maybe<Array<OrderingPartner>>;
  /** The VCC partner id that is used in VCC systems. Format: 3/6XX00000. XX = market code. 0000= CIS id/District number. */
  partnerId: Scalars['String']['output'];
  /** The organisation details of the partner doing business with Volvo cars. */
  partnerLegalEntity: PartnerLegalEntity;
  /** The physical address(s) of the partner. */
  partnerOrganizationalUnitFacilities: Array<PartnerOrganizationalUnitFacility>;
  partnerOrganizationalUnitNames: Array<PartnerOrganizationalUnitName>;
  /** The id for a partner that is represented in QW90 application. */
  qw90Code?: Maybe<Scalars['String']['output']>;
  /** Indicates what kind of role the retailer acts as like Authorized retailer, Administrative account, Studio, PopUp store, Fleet leads etc. */
  roleCode: RoleCode;
  sapParmaCode?: Maybe<Scalars['String']['output']>;
  /** The date on which the partner was terminated. */
  terminatedDate?: Maybe<Scalars['String']['output']>;
  /** Indicates if the partner is terminated. Applicable when no open orders exists. */
  terminatedIndicator?: Maybe<Scalars['Boolean']['output']>;
  /** A code which identifies the Sub Division of specification to manufacturing that is required for an order/buyer combination. */
  vccProgramMarket?: Maybe<Scalars['String']['output']>;
  /** Indicates if the retailer delivers any services to a general consumer and to be shown on volvocars.com. */
  visibleToPublic: Scalars['Boolean']['output'];
};

export type PartnerOrganizationalUnitFacility = {
  __typename?: 'PartnerOrganizationalUnitFacility';
  /** Indicates if the partner's facility/site is active or not */
  active: Scalars['Boolean']['output'];
  /** The visiting address of the partner site */
  addresses: Array<Address>;
  /** Only for CN market. The five character alphanumeric code that is registered in the PARMA application, for after-sales partners. */
  afterSalesParmaPartnerCode?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  descriptions?: Maybe<Array<Description>>;
  exclusiveShowroom?: Maybe<Scalars['Boolean']['output']>;
  exclusiveSite?: Maybe<Scalars['Boolean']['output']>;
  facilityId: Scalars['String']['output'];
  facilityName?: Maybe<Scalars['String']['output']>;
  facilityType?: Maybe<FacilityType>;
  /** Email id of the partner site that is used for general queries */
  generalContactEmail?: Maybe<Scalars['String']['output']>;
  internalContactEmail?: Maybe<Scalars['String']['output']>;
  /** GPSX coordinates of the partner site in decimal format */
  latitude?: Maybe<Scalars['String']['output']>;
  /** GPSY coordinates of the partner site in decimal format */
  longitude?: Maybe<Scalars['String']['output']>;
  /** The six digit numeric code that is registered in the PARMA application. */
  parmaCode?: Maybe<Scalars['Long']['output']>;
  /** The five character alphanumeric code that is registered in the PARMA application. */
  parmaPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Phone number of the partner site that is used for general queries */
  phone?: Maybe<Scalars['String']['output']>;
  /** @deprecated The field is deprecated and will be removed in the future */
  polPulsId?: Maybe<PulsId>;
  timeZone?: Maybe<Scalars['String']['output']>;
  /** The website urls for the different businesses of the partner. */
  urls?: Maybe<Array<Urls>>;
  vccPulsId?: Maybe<PulsId>;
};

export type PartnerOrganizationalUnitFacilityLocation = {
  __typename?: 'PartnerOrganizationalUnitFacilityLocation';
  brand: Brand;
  distanceFromPointKm?: Maybe<Scalars['Float']['output']>;
  distanceFromPointMiles?: Maybe<Scalars['Float']['output']>;
  isDistanceByRoad?: Maybe<Scalars['Boolean']['output']>;
  localRetailerCode: Scalars['String']['output'];
  partnerId: Scalars['String']['output'];
  partnerOrganizationalUnitFacility: PartnerOrganizationalUnitFacility;
  partnerOrganizationalUnitNames: Array<PartnerOrganizationalUnitName>;
};

export type PartnerOrganizationalUnitName = {
  __typename?: 'PartnerOrganizationalUnitName';
  /** The 2-character ISO language code */
  languageCode: Scalars['String']['output'];
  /** The name of the partner in the given language */
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type PartnerOrganizationalUnitsConnection = {
  __typename?: 'PartnerOrganizationalUnitsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PartnerOrganizationalUnitsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PartnerOrganizationalUnit>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PartnerOrganizationalUnitsEdge = {
  __typename?: 'PartnerOrganizationalUnitsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PartnerOrganizationalUnit;
};

export type PulsId = {
  __typename?: 'PulsId';
  customerNr?: Maybe<Scalars['String']['output']>;
  districtNr: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  partnerOrganizationalUnit?: Maybe<PartnerOrganizationalUnit>;
  /** Returns active and visible to public partner organizational unit facilities based on location */
  partnerOrganizationalUnitFacilityByLocation?: Maybe<ResourceResponseOfPartnerOrganizationalUnitFacilityLocation>;
  partnerOrganizationalUnits?: Maybe<PartnerOrganizationalUnitsConnection>;
};

export type QueryPartnerOrganizationalUnitArgs = {
  partnerId: Scalars['String']['input'];
};

export type QueryPartnerOrganizationalUnitFacilityByLocationArgs = {
  filter: GetPartnersByLocationQueryFilterInput;
};

export type QueryPartnerOrganizationalUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GetPartnerOrganizationalUnitsQueryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ResourceResponseOfPartnerOrganizationalUnitFacilityLocation = {
  __typename?: 'ResourceResponseOfPartnerOrganizationalUnitFacilityLocation';
  items: Array<PartnerOrganizationalUnitFacilityLocation>;
  numberOfObjects: Scalars['Int']['output'];
};

export enum RoleCode {
  /** Partner codes for a retail partner with a contract only for a workshop. */
  AuthorizedRepairer = 'AUTHORIZED_REPAIRER',
  /** Partner codes for a retail partner with a contract for sales and workshop. */
  AuthRtlr = 'AUTH_RTLR',
  /** Role Code that is used for facilities that support retailers to offload some support work to free-up capacity at retailers. */
  Compound = 'COMPOUND',
  /** Partner codes for a retail partner with a contract directly towards a NSC, mainly B2B. */
  DirectCustomer = 'DIRECT_CUSTOMER',
  /** Partner codes used for steering fleet leads from .com. */
  FleetLeads = 'FLEET_LEADS',
  /** IMPORTER */
  Importer = 'IMPORTER',
  /** NSC */
  Nsc = 'NSC',
  /** Partner codes used for ordering of internal cars or cars for internal use (i.e. Demo cars, press etc.). */
  NscInternalUse = 'NSC_INTERNAL_USE',
  /**
   * Fallback when value cannot be mapped to current schema
   * @deprecated Not to be used as input
   */
  Other = 'OTHER',
  /** PARTS_WAREHOUSE */
  PartsWarehouse = 'PARTS_WAREHOUSE',
  /** Partner codes used for volvo Popup stores, which are temporary 'stores', mainly used for communication on .com. */
  PopupStore = 'POPUP_STORE',
  /** Partner codes used for distribution of cars to certain locations. */
  Ports = 'PORTS',
  /** Partner codes for rental & finance company with a contract directly towards NSC. */
  RentalFinanceCustomer = 'RENTAL_FINANCE_CUSTOMER',
  /** Partner codes used for communication on .com and sales at volvo Studios. */
  Studio = 'STUDIO',
  /** Role Code that is used for 3rd party damage repairers handling damage repairs on behalf of/ in cooperation with a Volvo retailer. */
  ThirdPartyDamageRepairCenter = 'THIRD_PARTY_DAMAGE_REPAIR_CENTER',
}

export type SpecialDate = {
  __typename?: 'SpecialDate';
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  timings: Array<TimeInterval>;
};

export type TaxId = {
  __typename?: 'TaxId';
  /** The full description of the partner tax id / legal id */
  fullName: Scalars['String']['output'];
  /** Abbreviation for the partner tax id / legal id */
  id: Scalars['String']['output'];
  /** The preferential order denotes which is used primary, secondary, tertiary... in the market */
  preferentialOrder: Scalars['Int']['output'];
  /** The value of the partner tax id / legal id */
  value?: Maybe<Scalars['String']['output']>;
};

export enum TerritoryType {
  /** Selling represents the territory/ies that are assigned to the retailer with the primary responsibility for car sales */
  Selling = 'Selling',
  /** Servicing represents the territory/ies that are assigned to the retailer with the primary responsibility for car servicing */
  Servicing = 'Servicing',
}

export type TimeInterval = {
  __typename?: 'TimeInterval';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type Urls = {
  __typename?: 'Urls';
  digitalWeb?: Maybe<Scalars['String']['output']>;
  languageCode: Scalars['String']['output'];
  localOffers?: Maybe<Scalars['String']['output']>;
  mapLocation?: Maybe<Scalars['String']['output']>;
  newCarLocator?: Maybe<Scalars['String']['output']>;
  partsOffer?: Maybe<Scalars['String']['output']>;
  polestarServiceBooking?: Maybe<Scalars['String']['output']>;
  retailer?: Maybe<Scalars['String']['output']>;
  usedCarLocator?: Maybe<Scalars['String']['output']>;
  workshop?: Maybe<Scalars['String']['output']>;
};

export type PartnerByLocationQueryVariables = Exact<{
  filters: GetPartnersByLocationQueryFilterInput;
}>;

export type PartnerByLocationQuery = {
  __typename?: 'Query';
  partnerOrganizationalUnitFacilityByLocation?: {
    __typename?: 'ResourceResponseOfPartnerOrganizationalUnitFacilityLocation';
    numberOfObjects: number;
    items: Array<{
      __typename?: 'PartnerOrganizationalUnitFacilityLocation';
      partnerId: string;
      brand: Brand;
      localRetailerCode: string;
      distanceFromPointKm?: number | null;
      distanceFromPointMiles?: number | null;
      partnerOrganizationalUnitNames: Array<{
        __typename?: 'PartnerOrganizationalUnitName';
        languageCode: string;
        name: string;
      }>;
      partnerOrganizationalUnitFacility: {
        __typename?: 'PartnerOrganizationalUnitFacility';
        facilityName?: string | null;
        parmaPartnerCode?: string | null;
        generalContactEmail?: string | null;
        longitude?: string | null;
        latitude?: string | null;
        active: boolean;
        facilityId: string;
        addresses: Array<{
          __typename?: 'Address';
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          countryCode: string;
          languageCode: string;
          postalCode?: string | null;
          state?: string | null;
        }>;
        urls?: Array<{
          __typename?: 'Urls';
          languageCode: string;
          retailer?: string | null;
          newCarLocator?: string | null;
          usedCarLocator?: string | null;
          workshop?: string | null;
          mapLocation?: string | null;
          localOffers?: string | null;
        }> | null;
        capabilities: Array<{
          __typename?: 'Capability';
          name?: CapabilityType | null;
          b2CPhone?: string | null;
          b2BPhone?: string | null;
          openingHours?: {
            __typename?: 'OpeningHours';
            monday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            tuesday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            wednesday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            thursday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            friday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            saturday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            sunday: Array<{
              __typename?: 'TimeInterval';
              start: string;
              end: string;
            }>;
            specialDates: Array<{
              __typename?: 'SpecialDate';
              description?: string | null;
              startDate: string;
              endDate: string;
              timings: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
            }>;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type PartnerOrganizationalUnitsQueryVariables = Exact<{
  filter: GetPartnerOrganizationalUnitsQueryFilterInput;
}>;

export type PartnerOrganizationalUnitsQuery = {
  __typename?: 'Query';
  partnerOrganizationalUnits?: {
    __typename?: 'PartnerOrganizationalUnitsConnection';
    edges?: Array<{
      __typename?: 'PartnerOrganizationalUnitsEdge';
      node: {
        __typename?: 'PartnerOrganizationalUnit';
        partnerId: string;
        brand: Brand;
        market: string;
        partnerOrganizationalUnitNames: Array<{
          __typename?: 'PartnerOrganizationalUnitName';
          languageCode: string;
          name: string;
        }>;
        partnerOrganizationalUnitFacilities: Array<{
          __typename?: 'PartnerOrganizationalUnitFacility';
          facilityId: string;
          facilityName?: string | null;
          parmaCode?: any | null;
          parmaPartnerCode?: string | null;
          generalContactEmail?: string | null;
          internalContactEmail?: string | null;
          longitude?: string | null;
          latitude?: string | null;
          phone?: string | null;
          active: boolean;
          addresses: Array<{
            __typename?: 'Address';
            addressLine1?: string | null;
            addressLine2?: string | null;
            addressLine3?: string | null;
            addressLine4?: string | null;
            city?: string | null;
            countryCode: string;
            countryName?: string | null;
            district?: string | null;
            languageCode: string;
            postalCode?: string | null;
            state?: string | null;
          }>;
          urls?: Array<{
            __typename?: 'Urls';
            languageCode: string;
            retailer?: string | null;
            newCarLocator?: string | null;
            usedCarLocator?: string | null;
            workshop?: string | null;
            mapLocation?: string | null;
            localOffers?: string | null;
          }> | null;
          descriptions?: Array<{
            __typename?: 'Description';
            aboutUs?: string | null;
            languageCode: string;
            promotionText?: string | null;
            tagLine?: string | null;
          }> | null;
          capabilities: Array<{
            __typename?: 'Capability';
            contactPerson?: string | null;
            b2BEmail?: string | null;
            b2CEmail?: string | null;
            b2BFax?: string | null;
            name?: CapabilityType | null;
            b2CPhone?: string | null;
            b2BPhone?: string | null;
            openingHours?: {
              __typename?: 'OpeningHours';
              monday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              tuesday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              wednesday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              thursday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              friday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              saturday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              sunday: Array<{
                __typename?: 'TimeInterval';
                start: string;
                end: string;
              }>;
              specialDates: Array<{
                __typename?: 'SpecialDate';
                description?: string | null;
                startDate: string;
                endDate: string;
                timings: Array<{
                  __typename?: 'TimeInterval';
                  start: string;
                  end: string;
                }>;
              }>;
            } | null;
          }>;
        }>;
      };
    }> | null;
  } | null;
};
