export const debounce = <T extends (...args: any) => void>(
  func: T,
  delay: number,
  options: { leading?: boolean; trailing?: boolean } = {},
): ((...args: Parameters<T>) => void) => {
  const { leading = false } = options;

  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let isInvoked = false;

  return (...args: Parameters<T>) => {
    const callNow = leading && !timeoutId;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      timeoutId = null;
      if (!callNow) {
        func(...args);
      }
      isInvoked = false;
    }, delay);

    if (callNow && !isInvoked) {
      func(...args);
      isInvoked = true;
    }
  };
};
