'use client';
import { getDictionariesProvider } from '@vcc-www/react-translate';
import { DictionaryItemTypes } from './DictionaryItemTypes';

const providers: ReturnType<
  typeof getDictionariesProvider<DictionaryItemTypes>
> = getDictionariesProvider<DictionaryItemTypes>();

export const useSharedComponentsTranslate: typeof providers.useTranslate =
  providers.useTranslate;

export const SharedComponentsDictionariesProvider =
  providers.DictionariesProvider;
